import React from "react";
import {FaChevronCircleRight} from "react-icons/fa";

class CandidatesBanner extends React.Component {
  constructor({data, location }) {
    
    super({ data, location });
  }
  
  render() {
    return (
            <a className="candidates-banner" href="/candidates">
                    <h1>VIEW YOUR</h1> 
                    <h1>AE2022 CANDIDATES <FaChevronCircleRight size={38}/></h1>
            </a>
    );
  }
}

export default CandidatesBanner