import React from "react";
import {FaChevronCircleRight} from "react-icons/fa";

class CharterBanner extends React.Component {
  constructor({data, location }) {
    
    super({ data, location });
  }
  
  render() {
    return (
            // <a className="candidates-banner" href="/electionhub">
            //     <div className="">
            //       <a className="charter-hover" href="/electionhub">
            //       <img src="/images/election-hub-banner.jpg" class="candidates-banner-img charter-hover"></img>
            //         <a className="charter-button" href="/electionhub">Go To Election Hub</a>
            //       </a>
                
            //     {/* <div className="candidates-banner-h1-width" style={{marginLeft: "auto", marginRight: "auto"}}>
            //       <h1>VIEW YOUR</h1>
            //       <h1 className="can-banner-second-line"><span>AE2022 </span><span style={{whiteSpace: "nowrap"}}> CANDIDATES <FaChevronCircleRight class="banner-chevron" size={38}/></span></h1>
            //     </div> */}
            //   </div>
            // </a>
            <a class="candidates-banner" href="/charter">
              <h1>VIEW OUR</h1> 
              <h1>LATEST CHARTER <FaChevronCircleRight class="banner-chevron" size={38}/></h1>
            </a>
    );
  }
}

export default CharterBanner