import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LatestPosts from "../components/latestposts"
import CandidatesBanner from "../components/candidatesbanner"
import CharterBanner from "../components/charter_banner"

const rawHTML = `
<!-- Place <div> tag where you want the feed to appear -->
<div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div><!-- The Javascript can be moved to the end of the html page before the </body> tag --><script type="text/javascript">
/* curator-feed-default-feed-layout */
(function(){
var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
i.src="https://cdn.curator.io/published/b0ca1637-8aef-414f-afd6-ff75bce238a4.js";
e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
})();
</script>`

const index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <link rel="preload" as="image" href="/images/election-hub-banner.jpg"/>
      <link rel="preload" as="image" href="/images/election-hub.jpg"/>
      <link rel="preload" as="image" href="/images/questionnaire.jpg"/>
      <link rel="preload" as="image" href="/images/candidates.jpg"/>
      <link rel="preload" as="image" href="/images/endorsements.jpg"/>
      <Seo location={location} title="Home"/>
      <div className="home_intro" id="abc">
        <p>Progressive Politics NI is a political group setup to encourage & promote progressive politicians and political parties in Northern Ireland. It’s time for cross-community politics, which fights for equality, human rights, transparency and a fair quality of life for all.</p>
      </div>   
        {/*<div className="recent_posts">
          <LatestPosts></LatestPosts>
        </div>*/}
        {/* <div dangerouslySetInnerHTML={{ __html: rawHTML }} /> */}

        {typeof window !== "undefined" && (
          <div className="container">   
          <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a></div>
            <script type="text/javascript">
              {(function(){
              var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
              i.src="https://cdn.curator.io/published/b0ca1637-8aef-414f-afd6-ff75bce238a4.js";
              e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
              })()}
            </script>
          </div>
        )}
    </Layout>
  )
}

export default index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
